
































































































































import { defineComponent, onMounted, ref } from '@vue/composition-api'
import { gsap } from 'gsap'

export default defineComponent({
  name: 'IllustrationManometer',

  setup() {
    const isFillTimelineCompleted = ref(false)
    const pointer = ref<SVGPathElement | null>(null)
    const waterContainer = ref<SVGGElement | null>(null)
    const water = ref<SVGPathElement | null>(null)

    const movementTimeline = gsap.timeline({
      paused: true,
      repeat: -1,
      repeatDelay: 5,
    })

    const fillTimeline = gsap.timeline({
      paused: true,
      delay: 0.5,
      onComplete: () => {
        isFillTimelineCompleted.value = true
        movementTimeline.play()
      },
    })

    onMounted(() => {
      // Move the water container all the way to the bottom to hide it
      gsap.set(waterContainer.value, {
        transformOrigin: '50% 50%',
        y: 230,
      })

      // Pointer empty = -153deg
      // Pointer full = 118 deg
      gsap.set(pointer.value, {
        transformOrigin: '40.5% 69%',
        rotation: -153,
      })

      // Make path bigger to create wavy animation
      gsap.set(water.value, {
        transformOrigin: '0 0',
        scale: 1.55,
      })

      movementTimeline.addLabel('water-movement-1')

      movementTimeline.set(waterContainer.value, {
        y: -50,
      })

      movementTimeline.set(pointer.value, {
        rotation: 84,
      })

      movementTimeline.to(
        waterContainer.value,
        {
          delay: 0.25,
          duration: 1,
          translateY: -40,
        },
        'water-movement-1'
      )

      movementTimeline.to(
        pointer.value,
        {
          delay: 0.25,
          duration: 1,
          rotation: 74,
        },
        'water-movement-1'
      )

      movementTimeline.addLabel('water-movement-2')

      movementTimeline.to(
        waterContainer.value,
        {
          duration: 2,
          translateY: -45,
        },
        'water-movement-2'
      )

      movementTimeline.to(
        pointer.value,
        {
          duration: 2,
          rotation: 79,
        },
        'water-movement-2'
      )

      movementTimeline.addLabel('water-movement-3')

      movementTimeline.to(
        waterContainer.value,
        {
          duration: 1,
          translateY: -50,
        },
        'water-movement-3'
      )

      movementTimeline.to(
        pointer.value,
        {
          duration: 1,
          rotation: 84,
        },
        'water-movement-3'
      )

      fillTimeline.addLabel('water-fill')

      fillTimeline.to(
        waterContainer.value,
        {
          duration: 2.25,
          y: -50,
        },
        'water-fill'
      )

      fillTimeline.fromTo(
        water.value,
        {
          x: -150,
        },
        {
          duration: 0.25,
          repeat: 8,
          ease: 'none',
          x: 0,
        },
        'water-fill'
      )

      fillTimeline.to(
        pointer.value,
        {
          duration: 2.25,
          rotation: 86.5,
        },
        'water-fill'
      )

      fillTimeline.addLabel('water-adjusting')

      fillTimeline.to(
        water.value,
        {
          duration: 2,
          scale: 1,
        },
        'water-adjusting'
      )

      fillTimeline.to(
        pointer.value,
        {
          duration: 2,
          rotation: 84,
        },
        'water-adjusting'
      )
    })

    function onVisibilityChanged(isVisible: boolean): void {
      if (isVisible) {
        if (isFillTimelineCompleted.value) {
          movementTimeline.restart(true)
        } else {
          fillTimeline.play()
        }
      } else {
        if (fillTimeline.isActive()) {
          fillTimeline.pause()
        }
        if (movementTimeline.isActive()) {
          movementTimeline.pause()
        }
      }
    }

    return {
      pointer,
      waterContainer,
      water,
      onVisibilityChanged,
    }
  },
})
