


















































import { computed, defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'PatternWaterDrop',

  props: {
    id: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const patternId = computed(() => `${props.id}-pattern`)

    return {
      patternId,
    }
  },
})
