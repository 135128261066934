






import i18n from '@/setup/i18n'
import { gsap } from 'gsap'
import {
  computed,
  defineComponent,
  PropType,
  reactive,
  watch,
} from '@vue/composition-api'

export default defineComponent({
  name: 'NumberAnimated',

  props: {
    tag: {
      type: String,
      default: 'span',
    },

    value: {
      type: [String, Number],
      default: 0,
    },

    minimumFractionDigits: {
      type: Number as PropType<number | null>,
      default: null,
    },

    maximumFractionDigits: {
      type: Number as PropType<number | null>,
      default: null,
    },

    fractionDigits: {
      type: Number,
      default: 0,
    },

    duration: {
      type: Number,
      default: 0.45,
    },

    delay: {
      type: Number,
      default: 0,
    },

    currency: {
      type: Boolean,
      default: false,
    },

    abbreviate: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const data = reactive({
      tweenedNumber: 0,
    })

    watch(
      () => props.value,
      (newValue) => {
        gsap.to(data, {
          duration: props.duration,
          delay: props.delay,
          tweenedNumber: newValue,
        })
      },
      {
        immediate: true,
      }
    )

    const currencyOptions = computed(() => {
      return props.currency
        ? {
            style: 'currency',
            currency: 'USD',
          }
        : {}
    })

    const fractionDigitsOptions = computed(() => {
      return {
        minimumFractionDigits:
          props.minimumFractionDigits !== null
            ? props.minimumFractionDigits
            : props.fractionDigits,
        maximumFractionDigits:
          props.maximumFractionDigits !== null
            ? props.maximumFractionDigits
            : props.fractionDigits,
      }
    })

    const animatedNumber = computed(() => {
      return data.tweenedNumber.toLocaleString(i18n.locale, {
        ...currencyOptions.value,
        ...fractionDigitsOptions.value,
        ...(props.abbreviate
          ? {
              notation: 'compact',
              compactDisplay: 'short',
            }
          : undefined),
      })
    })

    return {
      animatedNumber,
    }
  },
})
