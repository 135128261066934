import { AxiosInstance } from 'axios'
import { api } from '../../client'
import { serviceUrl } from '../../utils'

const controller = serviceUrl('manometer')

function createManometerService(client: AxiosInstance) {
  return {
    get() {
      return client.get<ManometerViewModel>(controller.action('get'))
    },
    update(model: ManometerUpdateModel) {
      return client.post<IdReturnViewModel>(controller.action('update'), model)
    },
    dailyUpdate() {
      return client.get<void>(controller.action('dailyUpdate'))
    },
  }
}
export const manometerService = createManometerService(api.base)

export interface ManometerViewModel {
  manometerId: string
  manometerKey: number
  value: number
  incrementBy: number
  lastModifiedDate: Date
  lastModifiedBy: string
}
export interface ManometerUpdateModel {
  currentValue: number
  incrementBy: number
}
export interface IdReturnViewModel {
  id: string
  key: number
}
