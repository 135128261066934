




















































































































import LandingSection from '@/components/LandingSection.vue'
import PatternWaterDrop from '@/components/PatternWaterDrop.vue'
import CurveWaterWaves from '@/components/CurveWaterWaves.vue'
import IllustrationManometer from '@/components/illustrations/IllustrationManometer.vue'
import NumberAnimated from '@/components/number/NumberAnimated.vue'
import { defineComponent, onMounted, ref } from '@vue/composition-api'
import { manometerService } from '@/services/modules/manometer'

export default defineComponent({
  name: 'LandingSectionAboutUs',

  components: {
    LandingSection,
    IllustrationManometer,
    NumberAnimated,
    PatternWaterDrop,
    CurveWaterWaves,
  },

  setup() {
    const shouldAnimateNumber = ref(false)
    const manometerWaterValue = ref(0)

    function onManotemerTextVisibilityChange(isVisibile: boolean): void {
      if (isVisibile && !shouldAnimateNumber.value) {
        shouldAnimateNumber.value = true
      }
    }

    async function updateManometerDailyValue() {
      try {
        await manometerService.dailyUpdate()
      } catch (e) {
        // fail gracefully
      }
    }

    async function getManometerValue() {
      try {
        const {
          data: { value },
        } = await manometerService.get()

        manometerWaterValue.value = value
      } catch (e) {
        // fail gracefully
      }
    }

    onMounted(async () => {
      await updateManometerDailyValue()
      getManometerValue()
    })

    return {
      shouldAnimateNumber,
      onManotemerTextVisibilityChange,
      manometerWaterValue,
    }
  },
})
